// styles.js
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  cnBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85vh',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center',

    '& .MuiTypography-h3': {
      fontSize: '3rem',
      [theme.breakpoints.up('lg')]: {
        fontSize: '4rem',
      },
    },

    '& .MuiTypography-subtitle1': {
      fontSize: '1.4rem',
      [theme.breakpoints.up('lg')]: {
        fontSize: '2rem',
      },
    },
  },
}));

export default useStyles;
