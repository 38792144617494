import React from "react";
import { Button, Typography } from "@material-ui/core";
import useStyles from "../Styles";
import { Link } from "react-router-dom";
import { ABOUT_PATH } from "../../../constants/Routes";
import { motion } from "framer-motion";
import useAnimationInView from "../../../Hooks/useAnimationInView";

function AboutTitle({ title, animate, description }) {
  const classes = useStyles();
  const { ref, controls } = useAnimationInView();

  return (
    <motion.div
      ref={ref}
      className={classes.info}
      animate={controls}
      variants={animate}
      initial="hidden"
    >
      <Typography component="h3"> ABOUT US</Typography>
      <Typography gutterBottom variant="h4">
        {title}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        {description}
      </Typography>
      <div>
        <Button
          component={Link}
          to={ABOUT_PATH}
          variant="contained"
          color="secondary"
        >
          see more
        </Button>
      </div>
    </motion.div>
  );
}

export default AboutTitle;
