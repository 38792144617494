import React, { useRef } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import CustomTextFiled from "../../Components/CustomTextFiled/CustomTextFiled";
import useStyles from "./styles";
import emailjs from "@emailjs/browser";
import { useHistory } from "react-router-dom";

function Seller() {
  const methods = useForm();
  const classes = useStyles();
  const history = useHistory();

  const { control, setValue, watch } = methods;
  const websiteLink = watch("partnerType");

  const form = useRef();
  // const [submitted, setSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    const data = new FormData(form.current);
    data.append("to_name", "Rajat Rai"); // Replace with the actual recipient name

    emailjs
      .sendForm(
        "service_e0ohorf",
        "template_ij93rse",
        form.current,
        "Ut-I3MEh9aL26CkX_"
      )
      .then(
        (result) => {
          console.log(result.text);
          // Redirect to /thank-you
          history.push("/thank-you");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Container>
      <Box
        className={classes.seller}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography align="center" variant="h3" color="secondary">
          Partner with Us
        </Typography>
        <FormProvider {...methods}>
          <form ref={form} onSubmit={sendEmail}>
            <Grid container spacing={3}>
              <CustomTextFiled name="location" label="Location" size={12} />
              <FormControl className={classes.companyInput}>
                <InputLabel name="partnerType" id="websiteLinkLabel" className={classes.companyInput}>
                  Partner Type *
                </InputLabel>
                <Controller
                  render={({ field }) => (
                    <Select
                      labelId="websiteLinkLabel"
                      {...field}
                      onChange={(e) => {
                        setValue("partnerType", e.target.value);
                      }}
                      size={12}
                    >
                      <MenuItem value="Individual">Individual</MenuItem>
                      <MenuItem value="Company">Company</MenuItem>
                    </Select>
                  )}
                  control={control}
                  name="partnerType"
                />
              </FormControl>
              {websiteLink === "Company" && (
                <CustomTextFiled
                  name="companyName"
                  label="Company Name"
                  size={12}
                />
              )}
              <CustomTextFiled name="webLink" label="Website Link" size={12} />
              <CustomTextFiled name="phoneNo" label="Phone No" size={12} />
              <CustomTextFiled name="from_name" label="Name" size={12} />
              <CustomTextFiled
                name="from_email"
                label="Email"
                type="email"
                size={12}
              />
              <CustomTextFiled name="subject" label="Subject" size={12} />
              <CustomTextFiled
                name="message"
                label="Message"
                multiline
                rows={4}
                size={12}
              />
              <div className={classes.btnGroup}>
                <Button color="primary" variant="contained" type="submit">
                  Submit
                </Button>
              </div>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Container>
  );
}

export default Seller;
