import React from "react";
// import Title from "../../Components/Title/Title";
import ContactHeader from "../../Components/Contact/ContactHeader/ContactHeader";
import ContactForm from "../ContactForm/ContactForm";
import AdvertisingSection from "../../Components/AdvertisingSection/AdvertisingSection";

function Contact() {
  return (
    <>
      <ContactHeader title="Contact Us" />
      {/* <Title title="Who are we?" /> */}
      <ContactForm />
      <AdvertisingSection />
    </>
  );
}
export default Contact;
