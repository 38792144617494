import React from 'react'
import {Typography } from '@material-ui/core';
import { HOME_PATH } from '../../../constants/Routes';
// import FastfoodIcon from '@material-ui/icons/Fastfood';
import { Link } from 'react-router-dom';
import useStyles from './styles';
// import { Image } from '@material-ui/icons';
import helenkyle from "../../../Assets/CompanyLogo/helenkyle.png"

function Logo() {
    const classes = useStyles();

    return (
        <div className={classes.logo}>
            <Typography component={Link} to={HOME_PATH}>
                <img src={helenkyle} alt="helenkyle" className={classes.image}/>
            </Typography>  
        </div>
    )
}

export default Logo
