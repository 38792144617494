import React from "react";
import Header from "../../Components/Header/Header";
import ServiceSection from "../../Components/ServiceSection/ServiceSection";
import AboutSection from "../../Components/AboutSection/AboutSection";
import AdvertisingSection from "../../Components/AdvertisingSection/AdvertisingSection";
// import ProductsSection from "../../Components/ProductsSection/ProductsSection";
import HomeImage1 from "../../Assets/BannerImage/HomeImage1.png";

function Home() {
  return (
    <>
      <Header title="Luxury Bath Soap" />
      <AboutSection
        image={HomeImage1}
        description={
          "At Helen Kyle, we are passionate about providing you with a bathing experience that transcends the ordinary. As a leading name in the world of bath soap manufacturing, we take pride in crafting soap products that not only cleanse but elevate your daily ritual into a luxurious and refreshing journey."
        }
      />
      <ServiceSection />
      {/* <ProductsSection /> */}
      <AdvertisingSection />
    </>
  );
}

export default Home;
