import React from "react";
import { Typography } from "@material-ui/core";
import { HOME_PATH } from "../../../../constants/Routes";
// import FastfoodIcon from '@material-ui/icons/Fastfood';
import { Link } from "react-router-dom";
import useStyles from "./styles";
import helenkyle from "../../../../Assets/CompanyLogo/helenkyle_footer.png";

function FooterLogo() {
  const classes = useStyles();

  return (
    <div className={classes.logo}>
      <Typography component={Link} to={HOME_PATH}>
        <img src={helenkyle} alt="helenkyle" className={classes.image} />
        {/* <Typography component="h4" color='secondary'>HELEN KYLE</Typography> */}
      </Typography>
    </div>
  );
}

export default FooterLogo;
