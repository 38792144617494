import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./Pages/Home/Home";
import ShoppingCart from "./Pages/ShoppingCart/ShoppingCart";
import Checkout from "./Pages/Checkout/Checkout";
import Register from "./Pages/Register/Register";
import Login from "./Pages/login/Login";
import OurProducts from "./Pages/OurProducts/OurProducts";
import ThankYou from "./Components/Thankyou/Thankyou";
import Layout from "./layout/Layout";
import {
  HOME_PATH,
  SHOPPING_CART_PATH,
  CHECKOUT_PATH,
  SIGNUP_PATH,
  LOGIN_PATH,
  ABOUT_PATH,
  SELLER_PATH,
  CONTACT_US_PATH,
  THANK_YOU_PATH,
  OUR_PRODUCTS_PATH,
} from "./constants/Routes";
import CreateThemeProvider from "./Theme/Theme";
import About from "./Pages/About/About";
import Contact from "./Pages/Contact/Contact";
import Partner from "./Pages/Partner/Partner";
import "./App.css";
import ScrollToTop from "react-scroll-to-top";

function App() {
  return (
    <>
      <ScrollToTop
        className="scroll-to-top"
        smooth
        color="#fff"
        style={{
          backgroundColor: "#E59100",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
        }}
      />
      <CreateThemeProvider>
        <Router>
          <Layout>
            <Switch>
              <Route exact path={HOME_PATH}>
                <Helmet>
                  <title>Helen Kyle | Enhancing Style</title>
                </Helmet>
                <Home />
              </Route>
              <Route exact path={SHOPPING_CART_PATH}>
                <Helmet>
                  <title>Shopping - Helen Kyle | Enhancing Style</title>
                </Helmet>
                <ShoppingCart />
              </Route>
              <Route exact path={CHECKOUT_PATH}>
                <Helmet>
                  <title>Checkout - Helen Kyle | Enhancing Style</title>
                </Helmet>
                <Checkout />
              </Route>
              <Route exact path={SIGNUP_PATH}>
                <Helmet>
                  <title>Register - Helen Kyle | Enhancing Style</title>
                </Helmet>
                <Register />
              </Route>
              <Route exact path={LOGIN_PATH}>
                <Helmet>
                  <title>Login - Helen Kyle | Enhancing Style</title>
                </Helmet>
                <Login />
              </Route>
              <Route exact path={OUR_PRODUCTS_PATH}>
                <Helmet>
                  <title>Our Products - Helen Kyle | Enhancing Style</title>
                </Helmet>
                <OurProducts />
              </Route>
              <Route exact path={ABOUT_PATH}>
                <Helmet>
                  <title>About Us - Helen Kyle | Enhancing Style</title>
                </Helmet>
                <About />
              </Route>
              <Route exact path={SELLER_PATH}>
                <Helmet>
                  <title>Partner with Us - Helen Kyle | Enhancing Style</title>
                </Helmet>
                <Partner />
              </Route>
              <Route exact path={CONTACT_US_PATH}>
                <Helmet>
                  <title>Contact Us - Helen Kyle | Enhancing Style</title>
                </Helmet>
                <Contact />
              </Route>
              <Route exact path={THANK_YOU_PATH}>
                <Helmet>
                  <title>Thank You - Helen Kyle | Enhancing Style</title>
                </Helmet>
                <ThankYou />
              </Route>
            </Switch>
          </Layout>
        </Router>
      </CreateThemeProvider>
    </>
  );
}

export default App;
