import React from "react";
// import Title from "../../Components/Title/Title";
import PartnerHeader from "../../Components/Partner/PartnerHeader/PartnerHeader";
import Seller from "../Seller/Seller";
import AdvertisingSection from "../../Components/AdvertisingSection/AdvertisingSection";

function Partner() {
  return (
    <>
      <PartnerHeader title="Join Us" />
      {/* <Title title="Who are we?" /> */}
      <Seller />
      <AdvertisingSection />
    </>
  );
}
export default Partner;
