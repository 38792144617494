import { makeStyles } from "@material-ui/core";
import header from "../../Assets/BannerImage/HomeBanner2.png";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "180px",
    overflow: "hidden",
    backgroundImage: `url(${header})`,
    backgroundPosition: "center",
    backgroundSize: "100% 100%",
    zIndex: "1",
    marginBottom: "40px",
    [theme.breakpoints.up("sm")]: {
      //   backgroundPosition: "right",
      height: "300px",
    },
    [theme.breakpoints.up("md")]: {
      //   backgroundPosition: "right",
      height: "350px",
    },
    [theme.breakpoints.up("lg")]: {
      //   backgroundPosition: "right",
      height: "70vh",
    },
  },
  overlay: {
    position: "absolute",
    top: "0px",
    bottom: "0px",
    right: "0px",
    left: "0px",
    width: "100%",
    zIndex: "2",
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    height: "22vh",
    zIndex: "3",
    [theme.breakpoints.up("sm")]: {
      height: "38vh",
    },
    [theme.breakpoints.up("md")]: {
      height: "50vh",
    },
    [theme.breakpoints.up("lg")]: {
      height: "70vh",
    },
    "& h1": {
      color: "#fff",
      fontSize: "16px",
      zIndex: "3",
      textTransform: "upperCase",
      fontWeight: "400",
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        fontSize: "30px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "40px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "70px",
      },
    },
    "& h3": {
      color: theme.palette.secondary.main,
      fontSize: "25px",
      zIndex: "3",
      textTransform: "upperCase",
      fontFamily: theme.typography.caption.fontFamily,
      marginBottom: "-10px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "40px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "60px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "80px",
      },
    },
    "& a": {
      zIndex: "333",
      color: "#fff",
      fontSize: "8px",
      height: "18px",
      [theme.breakpoints.up("sm")]: {
        fontSize: "12px",
        height: "32px",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "14px",
        height: "36px",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "16px",
        height: "36px",
        width: "160px",
      },
    },
  },
}));

export default useStyles;
