// ThankYou.js
import React from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles"; // Import styles if needed

function ThankYou() {
  const classes = useStyles();

  return (
    <Box
      className={classes.cnBox}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography align="center" variant="h3" color="secondary">
        Thank You!
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        We appreciate your message. We will get back to you soon.
      </Typography>
    </Box>
  );
}

export default ThankYou;
