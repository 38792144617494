import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import {ABOUT_PATH, HOME_PATH, SELLER_PATH, CONTACT_US_PATH} from '../constants/Routes'

export const socialMediaItems = [
    {pathname : "https://web.facebook.com/" , icon: <FacebookIcon/>},
    {pathname : "https://twitter.com/" , icon: <TwitterIcon/>},
    {pathname : "https://www.linkedin.com/in/" , icon: <LinkedInIcon/>},
    {pathname : "https://www.instagram.com/helenkylecosmetics/" , icon: <InstagramIcon/>},
]

export const itemsLink = [
    {name:'Home' , path:HOME_PATH},
    {name:'About' , path:ABOUT_PATH},
    {name:'Partner With Us' , path:SELLER_PATH},
    {name:'Contact Us' , path:CONTACT_US_PATH},
]