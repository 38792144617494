import React from "react";
import AboutSection from "../../Components/AboutSection/AboutSection";
import AboutHeader from "../../Components/AboutSection/Components/AboutHeader/AboutHeader";
import AdvertisingSection from "../../Components/AdvertisingSection/AdvertisingSection";
import Title from "../../Components/Title/Title";
import About1 from "../../Assets/AboutUs/About1.png";
import About2 from "../../Assets/AboutUs/About2.png";
import About3 from "../../Assets/AboutUs/About3.jpg";
import About4 from "../../Assets/AboutUs/About4.jpg";

function About() {
  return (
    <>
      <AboutHeader title="Who are we?" />
      <Title title="Who are we?" />
      <AboutSection
        image={About1}
        title={"Make a Step"}
        description={
          "At Helen Kyle, we are your trusted source for a diverse range of bathsoaps that cater to every individual's unique preferences and skincare needs. Our company was founded on the belief that the simple act of bathing can be transformed into a luxurious and personalized experience. With an unwavering commitment to quality and innovation, we have become a leading name in the world of bat and skincare products."
        }
      />
      <AboutSection
        image={About3}
        title={"Customized Options"}
        isImageRight={false}
        description={
          "We take pride in offering a wide variety of soap types to suit your specific requirements. We believe in the importance of personalization. That's why we offer customizable soap options, allowing you to choose your preferred scents, ingredients, and packaging to create a unique and special product just for you."
        }
      />
      <AboutSection
        image={About4}
        title={"Advantages"}
        description={
          "Our customers are at the heart of everything we do. We continually strive to exceed your expectations, and your feedback drives our innovation. Your satisfaction is our greatest reward, and we are dedicated to earning your trust with every bar of soap we create."
        }
      />
      <AboutSection
        image={About2}
        title={"Nature of Work"}
        isImageRight={false}
        description={
          "Quality is the cornerstone of our soapmaking process. We meticulously select the finest natural ingredients, carefully blend them, and employ traditional craftsmanship techniques to create soaps that are not only gentle on your skin but also environmentally responsible. We source our ingredients sustainably, and our commitment to quality is unwavering."
        }
      />
      <AdvertisingSection />
    </>
  );
}

export default About;
