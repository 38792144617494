import {ABOUT_PATH, HOME_PATH, SELLER_PATH, CONTACT_US_PATH} from '../constants/Routes'
export const navbarLinks = [
    {name:'Home' , path:HOME_PATH},
    {name:'About' , path:ABOUT_PATH},
    // {name:'Sign up' , path:SIGNUP_PATH},
    {name:'Partner With Us' , path:SELLER_PATH},
    // {name:'product' , path:OUR_PRODUCTS_PATH},
    {name:'Contact Us' , path:CONTACT_US_PATH},

]
// OUR_PRODUCTS_PATH
// SIGNUP_PATH