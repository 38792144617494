export const categoriesItems = [
    'Four Cheese Pasta',
    'Pasta Carbonara',
    'Gnocchi with Tomato Sauce',
    'Pasta Con Pomodoro E Basilico',
    'Home-Style Baked Pasta',
    'Ravioli with Coconut Milk',
    'Spaghetti Bolognese',
    'Penne Ala Vodka',
    'Chicken Paste',
]