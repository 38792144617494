import React from "react";
import { Box, Container, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import FooterLogo from "./FooterLogo/FooterLogo";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
// import PhoneIcon from "@material-ui/icons/Phone";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import { itemsLink, socialMediaItems } from "../../../utils/footerDetails";
import { variantsHoverIcons } from "../../../Animation";
import { motion } from "framer-motion";

function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={classes.logoContainer}
        >
          <FooterLogo />
          <div className={classes.socialLink}>
            {socialMediaItems.map((item, index) => (
              <motion.div
                key={index}
                whileHover={variantsHoverIcons.whileHover}
                whileTap={variantsHoverIcons.whileTap}
              >
                <Link to={{ pathname: item.pathname }} target="_blank">
                  {item.icon}
                </Link>
              </motion.div>
            ))}
          </div>
          <div>
            <Typography className={classes.phoneNumber} variant="h6">
              <Typography className={classes.detailsContact} variant="h6">
                <PhoneOutlinedIcon />
                <a
                  href="tel:+917977727699"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  +91 7977727699
                </a>
              </Typography>
              {/* <Typography className={classes.detailsContact} variant="h6">
                <PhoneIcon />
                <a
                  href="tel:+9102220872010"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  +91 02220872010
                </a>
              </Typography> */}
              <Typography className={classes.detailsContact} variant="h6">
                <EmailOutlinedIcon />{" "}
                <a
                  href="mailto:Info@helenkyle.com"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Info@helenkyle.com
                </a>
              </Typography>
              <Typography className={classes.detailsContact} variant="h6">
                <LocationOnOutlinedIcon />
                <a
                  href="https://pgsindia.co.in/"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Mumbai, MH
                </a>
              </Typography>
            </Typography>
          </div>
        </Box>
        {/* ITEMS LIST OF FOOTER */}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="start"
          className={classes.listItems}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            className={classes.listItems}
          >
            {itemsLink.map((item, index) => (
              <Typography
                component={Link}
                key={index}
                className={classes.linkItems}
                to={item.path}
              >
                {item.name}
              </Typography>
            ))}
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="start"
            className={classes.listItems}
          >
            {itemsLink.map((item, index) => (
              <Typography
                component={Link}
                key={index}
                className={classes.linkItems}
                to={item.path}
              >
                {item.name}
              </Typography>
            ))}
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="start"
            className={classes.listItems}
          >
            {itemsLink.map((item, index) => (
              <Typography
                component={Link}
                key={index}
                className={classes.linkItems}
                to={item.path}
              >
                {item.name}
              </Typography>
            ))}
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="start"
            className={classes.listItems}
          >
            {itemsLink.map((item, index) => (
              <Typography
                component={Link}
                key={index}
                className={classes.linkItems}
                to={item.path}
              >
                {item.name}
              </Typography>
            ))}
          </Box>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="start"
            className={classes.listItems}
          >
            {itemsLink.map((item, index) => (
              <Typography
                component={Link}
                key={index}
                className={classes.linkItems}
                to={item.path}
              >
                {item.name}
              </Typography>
            ))}
          </Box>
        </Box>
        <Box
          className={classes.copyRight}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            component="h3"
            align="center"
            className={classes.linkItems}
          >
            © 2018 Web Spectrum
          </Typography>
          <Typography component="h3" className={classes.toolsUsed}>
            Helen Kyle Brands
          </Typography>
        </Box>
      </Container>
    </div>
  );
}

export default Footer;
