import React, { useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import CustomTextFiled from "../../Components/CustomTextFiled/CustomTextFiled";
import useStyles from "./styles";
import emailjs from "@emailjs/browser";
// import ThankYou from "../../Components/Thankyou/Thankyou";
import { useHistory } from "react-router-dom";

function ContactForm() {
  const methods = useForm();
  const classes = useStyles();
  const form = useRef();
  const history = useHistory();

  const sendEmail = (e) => {
    e.preventDefault();
    const data = new FormData(form.current);
    data.append("to_name", "Rajat Rai"); // Replace with the actual recipient name

    emailjs
      .sendForm(
        "service_e0ohorf",
        "template_m8myo9a",
        form.current,
        "Ut-I3MEh9aL26CkX_"
      )
      .then(
        (result) => {
          console.log(result.text);
          // Redirect to /thank-you
          history.push("/thank-you");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Container>
      <Grid container spacing={3} className={classes.ContactMain}>
        <Grid item xs={12} md={6}>
          <Box
            className={classes.cnBox}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
          >
            <Typography align="center" variant="h3" color="secondary">
              Team Helen
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              Thank you for choosing Helen Kyle as your trusted partner in
              skincare. We look forward to being a part of your daily wellness
              journey.
            </Typography>
          </Box>
        </Grid>

        {/* Right side - Contact form */}
        <Grid item xs={12} md={6}>
          <Box
            className={classes.contact}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography align="center" variant="h3" color="secondary">
              Contact Us
            </Typography>
            <FormProvider {...methods}>
              <form ref={form} onSubmit={sendEmail}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    {/* First Name */}
                    <CustomTextFiled
                      name="from_name"
                      label="First Name"
                      size={12}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* Last Name */}
                    <CustomTextFiled
                      name="from_name"
                      label="Last Name"
                      size={12}
                    />
                  </Grid>
                  <CustomTextFiled
                    name="from_email"
                    label="Email"
                    type="email"
                    size={12}
                  />
                  <CustomTextFiled name="subject" label="Subject" size={12} />
                  <CustomTextFiled
                    name="message"
                    label="Message"
                    multiline
                    rows={4}
                    size={12}
                  />
                  <div className={classes.btnGroup}>
                    <Button color="primary" variant="contained" type="submit">
                      Submit
                    </Button>
                  </div>
                </Grid>
              </form>
            </FormProvider>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ContactForm;
