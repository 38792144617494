import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  // image: {
  // },
  logo: {
    flex: 1,
    textDecoration: "none",
    "& a": {
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
    },
    "& img": {
      // width: "100%",
      height: "130px",
      borderRadius:"71px",
      [theme.breakpoints.up("md")]: {
        width: "30%",
        height: "30%",
        borderRadius:"79px"
      },
    },

    "& svg": {
      fontSize: theme.spacing(5),
      color: theme.palette.secondary.main,
    },
    "& h4": {
      fontSize: theme.spacing(3),
      fontWeight: "bold",
      fontFamily: theme.typography.caption.fontFamily,
    },
    [theme.breakpoints.down("md")]: {
      marginRight: "15px",
    },
  },
}));

export default useStyles;


// height: 110px;
//     width: 110px;
//     border-radius: 71px;
