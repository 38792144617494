import React from 'react'
import CheckoutSteps from '../../Components/CheckoutForm/CheckoutSteps';

function Checkout() {
  return (
      <CheckoutSteps/>
  )
}

export default Checkout
