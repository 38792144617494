import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "70vh",
    boxShadow: theme.shadows[4],
    marginTop: "60px",
    width: "300px",
    maxWidth: "85%",
    margin: "auto",
    padding: "20px",
    borderRadius: theme.shape.borderRadius,
  },
  seller: {
    // height: "110vh",
    // boxShadow:theme.shadows[4],
    // marginTop: "30px",
    marginBottom: "30px",
    width: "900px",
    maxWidth: "85%",
    margin: "auto",
    padding: "20px",
    borderRadius: theme.shape.borderRadius,

    [theme.breakpoints.up("lg")]: {
      // height: "108vh",
    },
  },
  companyInput: {
    width: "93%",
    paddingLeft: "11px",
    [theme.breakpoints.up("sm")]: {
      width: "96%",
    },
    [theme.breakpoints.up("md")]: {
      width: "97.5%",
    },
  },
  cnBox: {
    height: "10vh",
    marginTop: "60px",
    width: "600px",
    maxWidth: "85%",
    margin: "auto",
    padding: "20px",
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up("lg")]: {
      height: "70vh",
    },
  },
  contact: {
    height: "50vh",
    // boxShadow:theme.shadows[4],
    marginTop: "60px",
    width: "500px",
    maxWidth: "85%",
    margin: "auto",
    padding: "20px",
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up("lg")]: {
      height: "60vh",
    },
  },
  btnGroup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    marginTop: "20px",
    "& button": {
      marginTop: "15px",
      width: "100%",
      margin: "auto",
    },
    "& a": {
      marginTop: "15px",
      width: "100px",
    },
  },
}));

export default useStyles;
