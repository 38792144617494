export const HOME_PATH  = '/';
export const ABOUT_PATH  = '/about';
export const SHOPPING_CART_PATH = '/shopping-cart';
export const CHECKOUT_PATH = '/checkout';
export const SIGNUP_PATH = '/signup';
export const LOGIN_PATH = '/login';
export const OUR_PRODUCTS_PATH = '/our-product';
export const SELLER_PATH = '/partner-with-us';
export const CONTACT_US_PATH = '/contact-us';
export const THANK_YOU_PATH = '/thank-you';
