import service1 from "../Assets/ServicesSection/Service1.png";
import service2 from "../Assets/ServicesSection/Service2.jpg";
import service3 from "../Assets/ServicesSection/Service3.png";
// import service4 from "../Assets/ServicesSection/Service4.JPG";
import service5 from "../Assets/ServicesSection/Service5.jpeg";



export const serviceDetails = [
  {
    image: `${service1}`,
    title: "Luxury Soap",
    description:
      "We take pride in offering a widevariety of soap types to suit yourspecific requirements.",
  },
  {
    image: `${service2}`,
    title: "Variety",
    description:
      "We understand that no two individuals are alike. That's why we offer a diverse range of soap products",
  },
  {
    image: `${service3}`,
    title: "Customizable",
    description:
      "We believe in the importance of personalization. Hence we offer customizable soap options",
  },
  {
    image: `${service5}`,
    title: "Natural",
    description:
      "Our soaps are crafted using the finest natural ingredients sourced from around the world.",
  },
];
