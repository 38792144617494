import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "50px",
    height: "50px",
  },
  logo: {
    flex: 1,
    textDecoration: "none",
    "& a": {
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
    },
    "& img": {
      width: "40%",
      [theme.breakpoints.up("sm")]: {
        width: "15%",
      },
      [theme.breakpoints.up("lg")]: {
        width: "20%",
      },
    },
    "& svg": {
      fontSize: theme.spacing(5),
      color: theme.palette.secondary.main,
    },
  },
}));

export default useStyles;
